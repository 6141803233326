<template>
    <div class="h-full my-auto">
        <v-container fluid class="h-full my-auto">
            <v-row class="h-full my-auto">
                <v-col cols="12" class="h-full my-auto">
                    <div
                        class="relative flex items-top justify-center h-full bg-gray-100 dark:bg-gray-900 sm:items-center py-4 sm:pt-0">
                        <div
                            class="min-w-screen overflow-x-hidden  my-auto  outline-none focus:outline-none  items-center flex justify-center">
                            <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                                <div class="">
                                    <div class="text-center p-5 flex-auto justify-center align-middle">
                                        <svg class="w-6 h-6 mx-auto" fill="none" stroke="currentColor"
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122">
                                            </path>
                                        </svg>
                                        <h2 class="text-xl font-bold py-4 ">You're almost there</h2>
                                        <h2 class="text-lg pb-4 ">We Need You To First Link A Bank Account</h2>
                                        <p class="text-sm text-gray-500 px-8">Please click the button below to initiate
                                            the process to link your bank account.
                                            This app will be reading only your bank transactions and balance. This means
                                            it will have no access to initiating payments or any personal details such as card number, sort code etc.
                                        </p>

                                        <v-row justify="center" class="p-5 flex flex-col">
                                            <v-col cols="3" class="mx-auto">
                                                <plaid-link clientName="Pennytoast" env="development"
                                                    :publicKey="linkToken"
                                                    :product="['auth', 'transactions', 'balance']" :language="'en'"
                                                    :countryCodes="countryCodes" :isWebView=true :token=linkToken
                                                    webhook="https://api.pennytoast.com/api/webhooks" :onLoad=onLoad :onEvent=onEvent
                                                    :onExit=onExit :onSuccess=onSuccess :loading="loading"
                                                    @updateLoading="updateLoading"></plaid-link>
                                            </v-col>
                                        </v-row>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import PlaidLink from '../../components/Link/PlaidLink.vue'
import { mapGetters } from 'vuex'
// import axios from 'axios'
// import { Messages } from '@/utils/constants';

export default {
    name: 'Init',
    data() {
        return {
            publicToken: '',
            loading: false,
            error: null,
            eventName: null,
            metadata: null,
        }
    },

    components: { PlaidLink },

    created() {
        // request link token from server
        // this.$store.dispatch('requestLinkToken', this.$session.get('user').id)

        if (this.$session.get('user').cardLinked === true) {
            this.$router.push('/')
        }

        if (this.linkToken === null || this.linkToken === '') {
            // this.$router.push('/cards')
        }
    },

    computed: {
        ...mapGetters({
            linkToken: 'getLinkToken'
        }),
        user() {
            return this.$session.get('user')
        },
        countryCodes() {
            let codes = []
            codes.push(this.user.countryCode)
            return codes
        },
    },

    watch: {
    },

    methods: {
        onSuccess(token) {
            this.loading = false
            this.publicToken = token

            // send public token to server to exchange for access token
            let payload = { user_id: this.user.id, public_token: token }
            this.exchangePublicToken(payload)
        },
        onExit(error, metadata) {
            this.error = error
            this.metadata = metadata
            this.loading = false
        },
        onEvent(eventName, metadata) {
            this.eventName = eventName
            this.metadata = metadata
        },
        onLoad() {
        },
        updateLoading(value) {
            this.loading = value
        },
        async exchangePublicToken() {},
        // async exchangePublicToken(payload) {
        //     try {
        //         await axios.post(process.env.VUE_APP_API_URL + '/exchange-token', payload, { headers: { "Content-Type": "application/json" } })
        //         this.publicToken = null

        //         // we need to reload user token to get the new access token. We can use the refresh token to get a new access token
        //         // this will ensure we have updated data without the user logging in again
        //         await this.refreshUserToken()
        //     } catch (error) {
        //         this.$store.dispatch('showSnackBar', {status: true, message: error.response.data.message, type: Messages.ERROR})
        //     }
        // },
        async refreshUserToken() {
            // await axios
            //     .get(process.env.VUE_APP_API_URL + '/refreshToken', {
            //         'Authorization': 'Bearer ' + this.$session.get('refresh_token')
            //     })
            //     .then((response) => {
            //         // remove vue session
            //         this.$session.destroy()

            //         // start new vue session
            //         this.$session.start()

            //         // save the new token
            //         this.$session.set('access_token', response.data.access_token)
            //         this.$session.set('refresh_token', response.data.refresh_token)
            //         this.$session.set('user', response.data.user)

            //         // redirect to dashboard
            //         this.$router.push("/");
            //     })
            //     .catch((error) => {
            //         if(error.response.data.message === undefined) {
            //             this.$store.dispatch('showSnackBar', {status: true, message: 'Something went wrong', type: Messages.SUCCESS})
            //         } else {
            //             this.$store.dispatch('showSnackBar', {status: true, message: error.response.data.message, type: Messages.ERROR})
            //         }
            //     })
        }
    }
}
</script>